import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "general-guidance"
    }}>{`General guidance`}</h2>
    <p><em parentName="p">{`Loading`}</em>{` spinners are used when retrieving data or performing slow computations, and help to notify users that loading is underway. The waiting experience is a crucial design opportunity. Although it may not be obvious what is occurring on the back-end, we can communicate clearly to reassure the user that progress is happening.`}</p>
    <p>{`It is best practice to use a loading spinner whenever the wait time is anticipated to be longer than three seconds.`}</p>
    <h2 {...{
      "id": "size"
    }}>{`Size`}</h2>
    <p>{`Loading spinners may be scaled down if the loading experience is contextual to a certain item on the page.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "45.380434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsSAAALEgHS3X78AAABGElEQVQoz2NgIBL4+PgwREZGMoSFhYH5MJoEwAMiGKEYBZBkmKK8LJiWl5ViUVJUkFJUkJPW19PmT01LZw0JDmYDGsZIkqEx8SlgOi4xlS02LkE5Jj5ZISU9VzgnO0swKytLcMq0Gawwta/efiTGyP9Q+i8TkM0NxBzYVL39+IM4F166/wVMn77zne/S3XcOl++9s3v04pPNp48fLT4C8cu3n6xfvvnEC3HhJ8IGfnj/jglEf/3ySe/li+fPnj55fP/Dhw8PP3/+/Ojz50/3gYa8efPuoznYle8+MhE08PbDN2D66r3XjI9ffGC9fucJ65Pnb1i/fvnC8uHjJ5b/fz+x/v//ERwx//9/Is7bb968IRzS/7BHCAC8Bn+p1oM3EwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Small loader",
            "title": "Small loader",
            "src": "/static/49aa8e837082c8e7c6e17f57edfc0978/fb070/loading-usage-2.png",
            "srcSet": ["/static/49aa8e837082c8e7c6e17f57edfc0978/d6747/loading-usage-2.png 288w", "/static/49aa8e837082c8e7c6e17f57edfc0978/09548/loading-usage-2.png 576w", "/static/49aa8e837082c8e7c6e17f57edfc0978/fb070/loading-usage-2.png 1152w", "/static/49aa8e837082c8e7c6e17f57edfc0978/c3e47/loading-usage-2.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      